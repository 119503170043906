import { Footer } from '../../components/Footer/Footer';
import Headers from '../../components/Headers/Headers';
import { SideNavigation } from '../../components/SideNavigation/SideNavigation';
import FDICBanner from '../../shared/digitalbank/FDICBanner/FDICBanner';
import { usePageLayout } from '../../useHooks/LayoutHooks/usePageLayout';
import { ReduxWithData } from '../ReduxWithData/ReduxWithData';
import './DesktopLayout.scss';
import { IDesktopLayoutProps } from './interfaces/IDesktopLayoutProps';
/**
 * layout for desktop version of application. Used as a wrapper for desktop pages. Has a header, navigation, and footer
 * @param children a JSX element
 */
export const DesktopLayout = (props: IDesktopLayoutProps) => {
  const { isAccountInformationLoaded, gotUserContactInfo, isAccountDetailsLoaded } = usePageLayout()

  return (
    <div className="Desktop-Layout-Wrapper">
      <Headers />
      {((isAccountInformationLoaded === "Success" || isAccountInformationLoaded === "Loading") && (gotUserContactInfo === "Success" || gotUserContactInfo === "Loading") && (isAccountDetailsLoaded === "Success" || isAccountDetailsLoaded === "Loading")) ?
        <>
          {(gotUserContactInfo === "Success" || gotUserContactInfo === "Loading") &&
            <div className="middle">
              <div className="Side-Navigation">
                <SideNavigation />
              </div>
              <div className='Children-Wrapper'>
                <div className="Children">{props.children}</div>
                <div className="footer">
                  <Footer />
                </div>
              </div>
            </div>
          }
        </>
        :
        <div className="middle">
          <div className='Children-Wrapper error'>
            <div className="Children">{props.children}</div>
            <div className="footer">
              <Footer />
            </div>
          </div>
        </div>
      }
      <ReduxWithData />
    </div>
  );
};

