import { useAppSelector } from '../../../../../app/hooks'
import { RootState } from '../../../../../app/store'
import './MobileGraceLandingPage.scss'
import bluePencil from '../../../../../assets/bluePencil.svg'
import { calculateMaturityDate } from '../../../../../utils/calculateMaturityDate'
import maturityCalendar from '../../../../../assets/maturityCalendar.svg'
import greyRightArrow from '../../../../../assets/greyRightArrow.svg'
import { useHistory } from 'react-router-dom'
import { IGraceLandingPageProps } from '../interfaces/IGraceLandingPageProps'
import { convertDateTommddyyyy } from '../../../../../utils/DateUtils'
import { displayCurrency } from '../../../../../utils/displayCurrency'
import redTrashCan from '../../../../../assets/redTrashCan.svg'
import blueDownChevron from '../../../../../assets/blueDownChevron.svg'
import { dateLongHand } from '../../../../../utils/dateLongHand'
import { convertInterestRateFormat } from '../../../../../utils/convertInterestRateFormat'

/**Renders the Grace Period landing page for the mobile version */
const MobileGraceLandingPage = (props: IGraceLandingPageProps) => {
    const history = useHistory()
    const selectedAccount = useAppSelector((state: RootState) => state.selectedAccount.selectedAccount)
    const { setChangeCD, setFundTransfer, setCloseAccount, transferType, setOpenCancelFunds, showPhoneNumber, setShowPhoneNumber } = props
    const isAccountDetailsLoaded = useAppSelector((state: RootState) => state.selectedAccount.isAccountDetailsLoaded)

    return (
        <div className="mobile-grace-landing-wrap">
            {isAccountDetailsLoaded !== "Loading" ?
                <>
                <div className="top-card">
                    <div className="header-text-wrap">
                        <span className="title-text h3">Grace period options</span>
                        <span className="sub-title-text small">Your CD is currently in its 10-day grace period. You can make changes to the term, or add or withdraw funds, until {dateLongHand(convertDateTommddyyyy(new Date(selectedAccount.graceEndDate).toLocaleDateString("en-US", {timeZone: "America/Los_Angeles"})))}.</span>
                    </div>
                    <div className="details-wrap">
                        <span className="details-header boldLarge">Your current CD details</span>
                        <div className="option-wrap">
                            <div className="left-side">
                                <span className="option-text boldSmall">Renewed on</span>
                                <span className="option-value regular">{convertDateTommddyyyy(new Date(selectedAccount.graceStartDate).toLocaleDateString("en-US", {timeZone: "America/Los_Angeles"}))}</span>
                            </div>
                        </div>
                        <div className="option-wrap">
                            <div className="left-side">
                                <span className="option-text boldSmall">CD Description</span>
                                <span className="option-value regular">{selectedAccount.graceTermRenewal.cdGroup} {selectedAccount.graceTermRenewal.cdTerm}-month - {convertInterestRateFormat(selectedAccount.graceTermRenewal.apy.substring(0,4))}%</span>
                                <div className="pill-wrap">
                                {selectedAccount.isGracePeriod && <span className="maturity-pill mobileSemiboldSmall">Matures on {convertDateTommddyyyy(calculateMaturityDate(selectedAccount.graceStartDate, selectedAccount.graceTermRenewal.cdTerm))}</span>}
                                </div>
                            </div>
                            <img className="edit-pencil" src={bluePencil} alt="edit cd" onClick={() => setChangeCD(true)} />
                        </div>
                        <div className="option-wrap">
                            <div className="left-side">
                                <span className="option-text boldSmall">Add or withdraw funds</span>
                                <span className="option-value regular cls_mask">
                                {selectedAccount.gracePeriodFundTransfer.transferAccount !== "" ?
                                    transferType === "Add" ? 
                                        `Transfer ${displayCurrency(selectedAccount.gracePeriodFundTransfer.transferAmount as number)} from ${selectedAccount.gracePeriodFundTransfer.transferAccount}`
                                        :
                                        `Transfer ${displayCurrency(selectedAccount.gracePeriodFundTransfer.transferAmount as number)} from ${selectedAccount.accountTitle} ${selectedAccount.accountNumber.slice(selectedAccount.accountNumber.length - 4, selectedAccount.accountNumber.length)}`
                                    :
                                    `No transfer`}
                                </span>
                            </div>
                            {selectedAccount.gracePeriodFundTransfer.transferAccount === "" ?
                                <img data-testid="grace-funds" className="edit-pencil" src={bluePencil} alt="edit" onClick={() => setFundTransfer(true)} />
                                :
                                <img data-testid="grace-funds" className="edit-pencil" src={redTrashCan} alt="edit" onClick={() => setOpenCancelFunds(true)} />
                            }
                        </div>
                        <span className="close-link regular" onClick={() => setCloseAccount(true)}>Close this account</span>
                        {/* <div className="close-account-wrap">
                            <img data-testid="phone-chevron" className={!showPhoneNumber ? "close-chevron close" : "close-chevron open"} src={blueDownChevron} alt="blue downward facing chevron" onClick={() => setShowPhoneNumber(!showPhoneNumber)} />
                            <span data-testid="phone-text" className="close-link regular" onClick={() => setShowPhoneNumber(!showPhoneNumber)}>Close this account</span>
                        </div>
                        {showPhoneNumber &&
                            <span className="phone-text regular">To close your account, please call us at&nbsp;<a className="blue-text regular" href={"tel:18776281610"}>877-628-1610</a>.</span>
                        } */}
                    </div>
                </div>
                <div className="bottom-card" onClick={() => history.push(`/account/${selectedAccount.accountNumber}/maturity`)}>
                    <div className="left-side">
                        <img src={maturityCalendar} alt="maturity calendar" />
                        <div className="column-stack">
                            <span className="header-text boldSmall">Looking for your maturity plan?</span>
                            <span className="sub-header-text small">Choose what happens to your new CD when it matures by changing your maturity plan.</span>
                        </div>
                    </div>
                    <img src={greyRightArrow} alt="open link" />
                </div>
                </>
                :
                <div className="mobile-grace-landing-loading-wrap">
                    <div className="loading-box top" />
                    <div className="loading-box bottom" />
                </div>
            }
        </div>
    )
}

export default MobileGraceLandingPage