/* eslint-disable @typescript-eslint/no-unused-vars */
import c1bLogoDark from '../../../../assets/c1bLogoDark.svg'
import questionMarkDark from '../../../../assets/questionMarkDark.svg'
import './MobileHeader.scss'
import { useHeader } from "../../../../useHooks/componentHooks/headerComponentHook"
import FDICBanner from '../../../../shared/digitalbank/FDICBanner/FDICBanner'

/**
 * The MobileHeader component displays the mobile header view for digital bank.  When the screens pixel size is less than 800
 * this component will be called from the Headers wrapper component.
 * @param props currently no props are being used
 * @returns mobile header view
 */
const MobileHeader = () => {
    const { history, initialClick, setInitialClick, showMenu, setShowMenu } = useHeader()

    return (
        <div className="mobile-wrapper-stack">
            <div className="Mobile-Wrapper">
                <div className="C1b-Logo">
                    <img src={c1bLogoDark} alt="credit-one-bank-logo" style={{ height: "30px", cursor: "pointer" }} onClick={() => history.push("/")}/>
                </div>
                <div className="Right-Side">
                    <img data-testid="help-icon" src={questionMarkDark} alt="Help Button" style={{ height: "24px", cursor: "pointer" }} onClick={() => history.push('/knowledge-base')} />
                    {/* <img src={hamburgerMenu} alt="Menu Button" style={{height: "24px", marginLeft:"21px", cursor:"pointer"}} onClick={() => {setShowMenu(!showMenu); setInitialClick(true)}}/> */}
                </div>
            </div>
            <FDICBanner />
            {/* {initialClick &&
                <div>
                    {showMenu &&
                        <div className="Hide-Background" />
                    }
                    <div className={showMenu ? "Show-Mobile-DropDown" : "Close-Mobile-DropDown"}>
                        <MobileDropDown showMenu={showMenu} setShowMenu={setShowMenu}/>
                    </div>
                </div>
            } */}
        </div>
    )
}

export default MobileHeader