import { IAccountHeaderProps } from './interfaces/IAccountHeaderProps'; 
import './AccountHeader.scss'; 
import { useAccountHeader } from '../../useHooks/componentHooks/useAccountHeader/useAccountHeader';
import arrowLeft from '../../assets/arrowLeft.svg'
import { setStartBeneficiaryFlow } from '../../slices/selectedAccountSlice/selectedAccountSlice';
import { Pill } from '../Pill/Pill';
import CurrentBalanceHeader from '../../shared/digitalbank/CurrentBalanceHeader/CurrentBalanceHeader';
import { toggleOnTransactionDetails } from '../../slices/transactions/transactionsSlice';

/** 
 * The component creates the limited information account header used on flows such as transaction history
 * statements, beneficiaries, maturity settings and so on.
 * @param returnMessage: string, setDisplayDetails: boolean
 */ 
export const AccountHeader = (props: IAccountHeaderProps) => {  
  const { returnMessage, setDisplayDetails, showPill, page, isOnTransactions = false } = props;
  const { selectedAccount, history, dispatch, windowState, isAccountDetailsLoaded, previousPage } = useAccountHeader();
  const messageText = previousPage !== undefined ? previousPage : returnMessage;

  return ( 
    <div className={windowState !== "Mobile" ? "card-wrapper account-header" : "card-wrapper account-header mobile"}>
    <div className="container"> 
      {windowState !== "Mobile" ?
        <>
        {isAccountDetailsLoaded !== "Loading" ?
          <div className={`basic-account desktop ${(isOnTransactions) && `transactions`}`}>
            <div>
            <div className="return-wrapper" >
              {/* {returnMessage === "Back to Account Details" &&
                <>
                <img data-testid="header-back-chevron" className="return-arrow" src={arrowLeft} alt="Back button" onClick={() => setDisplayDetails(false)}/>
                <span className=" return text regular" onClick={() => setDisplayDetails(false)}>{messageText}</span>
                </>
              } */}
              {returnMessage === "Account Details" &&
                <>
                <img data-testid="header-back-chevron" className="return-arrow" src={arrowLeft} alt="Back button" onClick={() => history.goBack()}/>
                <span className="return text white regular" onClick={() => history.goBack()}>{messageText}</span>
                </>
              }
              {returnMessage === "Account Settings" &&
                <>
                <img data-testid="header-back-chevron" className="return-arrow" src={arrowLeft} alt="Back button" onClick={() => {dispatch(setStartBeneficiaryFlow(false)); history.goBack()}}/>
                <span className="return text white regular" onClick={() => {dispatch(setStartBeneficiaryFlow(false)); history.goBack()}}>{messageText}</span>
                </>
              }
              {returnMessage === "Maturity Plan" &&
                <>
                <img data-testid="header-back-chevron" className="return-arrow" src={arrowLeft} alt="Back button" onClick={() => history.goBack()}/>
                <span className="return text regular" onClick={() => {history.goBack()}}>{messageText}</span>
                </>
              }
            </div>
            <div className="info-wrap">
              <div className="description">
                  <span className="account-description text white mobileh2">{selectedAccount?.accountTitle}</span>
                  {/* {showPill && <Pill title="Pending Closure" sx={{backgroundColor: "var(--softRed)", color: "var(--darkRed)"}}/>} */}
              </div>
              <span className="pipe"></span>
              <span className="account-type-number mobileRegular">{selectedAccount?.accountType} {" "}
                  {selectedAccount.accountNumber?.slice(selectedAccount?.accountNumber.length-4, selectedAccount.accountNumber.length)}
                  {showPill && <Pill title="Pending Closure" sx={{backgroundColor: "var(--softRed)", color: "var(--darkRed)", marginLeft: '15px'}}/>}
              </span>
            </div>
            </div>
            {isOnTransactions && <CurrentBalanceHeader />}
          </div>
          :
          <div className="desktop-header-loading-state">
            <div className="loading-header-container">
              <div className="header-left">
                <div className="top-row"/>
                  <div className="bottom-row">
                  <div className="left" />
                  <div className="divider-line"/>
                  <div className="right" />
                </div>
              </div>
              {page === "transaction-history" &&
                <div className="header-right">
                  <div className="top-row"/>
                  <div className="bottom-row"/>
                </div>
              }
            </div>
          </div>
        }
        </>
        :
        <>
        {isAccountDetailsLoaded !== "Loading" ?
          <div className="basic-account mobile">
            <div className="account-header-return-wrap" >
              {returnMessage === "Back to Account Details" &&
                <>
                <img data-testid="header-back-chevron" className="return-arrow mobile" src={arrowLeft} alt="Back button" onClick={() => {setDisplayDetails(false); dispatch(toggleOnTransactionDetails(false))}}/>
                <span className="return text regular" onClick={() => {setDisplayDetails(false); dispatch(toggleOnTransactionDetails(false))}}>{messageText}</span>
                </>
              }
              {returnMessage === "Account Details" &&
                <>
                <img data-testid="header-back-chevron" className="return-arrow mobile" src={arrowLeft} alt="Back button" onClick={() => history.goBack()}/>
                <span className="return text regular" onClick={() => history.goBack()}>{messageText}</span>
                </>
              }
              {returnMessage === "Account Settings" &&
                <>
                <img data-testid="header-back-chevron" className="return-arrow mobile" src={arrowLeft} alt="Back button" onClick={() => {dispatch(setStartBeneficiaryFlow(false)); history.goBack()}}/>
                <span className="return text regular" onClick={() => {dispatch(setStartBeneficiaryFlow(false)); history.goBack()}}>{messageText}</span>
                </>
              }
              {returnMessage === "Maturity Plan" &&
                <>
                <img data-testid="header-back-chevron" className="return-arrow" src={arrowLeft} alt="Back button" onClick={() => history.goBack()}/>
                <span className="return text regular" onClick={() => {history.goBack()}}>{messageText}</span>
                </>
              }
            </div>
            <div className="info-wrap mobile-section">
              {/* <div className="top-line"> */}
                <span className="account-description text mobileBoldRegular">{selectedAccount?.accountTitle}</span>
                <span className="account-type-number regular">{selectedAccount?.accountType} {" "}
                  {selectedAccount.accountNumber?.slice(selectedAccount?.accountNumber.length-4, selectedAccount.accountNumber.length)}
                </span>
              {/* </div> */}
              <div className="pill-wrap">
                {showPill && <Pill title="Pending Closure" sx={{backgroundColor: "var(--softRed)", color: "var(--darkRed)"}}/>}
              </div>
            </div>
          </div>
          :
          <div className="mobile-header-loading-state">
            <div className="top" />
            <div className="bottom-row">
              <div className="left" />
              <div className="right" />
            </div>
          </div>
        }
        </>
      }
    </div> 
    </div>
  ); 
}; 
