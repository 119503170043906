import './ReroutePage.scss';
import creditCard from '../../assets/credit-card.png'
import digitalBank from '../../assets/digital-bank-icon.png';
// import Headers from '../../components/Headers/Headers';
import { Footer } from '../../components/Footer/Footer';
import { useHistory } from 'react-router';
import { CONFIG } from '../../utils/globalVariables';
import { useEffect } from 'react';
import c1bLogo from '../../assets/c1bLogoSecondary.svg'
import { useWindowState } from '../../Context/AccountContext/useWindowState';
import FDICBanner from '../../shared/digitalbank/FDICBanner/FDICBanner';

/**
 * Returns the entitlements for a user
 */
export const getEntitlements = (): Array<string> => {
  const OKTA_TOKEN = JSON.parse(localStorage.getItem('okta-token-storage') || '{}');
  const ENTITLEMENTS = OKTA_TOKEN?.idToken?.claims?.productEntitlements;

  return ENTITLEMENTS || []
}

/**
 * Returns if the user is only a credit card customer
 */
export const isOnlyCreditCardCustomer = () => {
  const ENTITLEMENTS = getEntitlements();
  return ENTITLEMENTS.length === 1 && ENTITLEMENTS[0] === 'CREDITCARD'
}

/**
 * Returns if a user is a credit card and digital bank customer
 */
export const isBothCustomer = () => {
  const ENTITLEMENTS = getEntitlements();
  return ENTITLEMENTS.length > 1 && ENTITLEMENTS.includes('CREDITCARD')
}

/**
 * Returns if a user is only a digital bank customer
 */
export const isOnlyDBCustomer = () => {
  const ENTITLEMENTS = getEntitlements();
  return ENTITLEMENTS.length > 1 && !ENTITLEMENTS.includes('CREDITCARD')
}


export const ReroutePage = () => {
  const history = useHistory();
  const { windowState } = useWindowState()

  useEffect(() => {
    if (isOnlyDBCustomer()) {
      history.push('/');
    }
  }, [])

  return <div className="reroute-page">
    <div >
      {/* <Headers /> */}
      {windowState !== "Mobile" && 
        <div className="fdic-convergence-wrapper">
          <FDICBanner />
        </div>  
      }
      <div className={`header-wrapper ${windowState}`}>
        <img className={`bank-icon ${windowState}`} src={c1bLogo} alt="credit one bank logo" />
      </div>
      {windowState === "Mobile" && 
        <div className="fdic-convergence-wrapper Mobile">
          <FDICBanner />
        </div>
      }
    </div>
    {/* <SitePicker /> */}
    {/* <CreditCardModal /> */}
    {isBothCustomer() && <SitePicker />}
    {isOnlyCreditCardCustomer() && <CreditCardModal />}

    <Footer />
    <div data-testid="123456_loaded" id="123456_loaded" style={{ visibility: 'hidden', position: 'fixed', zIndex: -100 }} />
  </div >
}


/**
 * The picker modal to pick to go to the credit card or digital bank site
 */
const SitePicker = () => {
  const history = useHistory();
  const { windowState } = useWindowState()
  return <div style={{ width: 'auto', margin: '0 auto' }}>
    <div className={`container ${windowState}`}>
      <div className="top"><span>Where would you like to go?</span></div>
      <div className="bottom">
        <button
          onClick={() => { window.location.href = CONFIG.CC_SITE_REDIRECT_URL }}>
          <img src={creditCard} alt="Go to your credit card account" />
          <div>Credit cards</div>
        </button>
        <button style={{ padding: '24px 65px' }}
          onClick={() => { history.push('/'); sessionStorage.setItem("isAuthenticated", "true") }}>
          <img style={{
            width: '131px',
            marginTop: '10px'
          }} src={digitalBank} alt="Go to your CD and Savings account" />
          <div>CD & Savings</div>
        </button>
      </div>
    </div>

  </div>
}

/**
 * The credit card modal to display when the user only has credit card entitlements
 */
const CreditCardModal = () => {
  const { windowState } = useWindowState()
  return <div style={{ width: 'auto', margin: '0 auto' }}>
    <div className={`container cc-picker-container ${windowState}`}>
      <img src={creditCard} alt="Looking for your credit card?" />
      <div
        className="top"
        style={{ marginTop: '-32px' }}>
        <span>Looking for your credit card?</span>
      </div>
      <div className="secondary-text">Please click below to sign in to your credit card account. We apologize for any inconvenience.</div>
      <button
        onClick={() => { window.location.href = CONFIG.CC_SITE_REDIRECT_URL }}
        className="Button btn-primary">Go to credit card
      </button>
    </div>
  </div>
}