import './FDICBanner.scss'
import fdicLogoWhite from '../../../assets/FDIC LogoWhite.svg'
import { useWindowState } from '../../../Context/AccountContext/useWindowState'


const FDICBanner = () => {
    const { windowState } = useWindowState()

    return (
        <div className={`fdic-logo-wrap ${windowState}`}>
            <img src={fdicLogoWhite} alt="fdic logo" />
            <p className={`fdic-logo-text ${windowState}`}>FDIC-Insured - Backed by the full faith and credit of the U.S. Government</p>
        </div>
    )
}

export default FDICBanner