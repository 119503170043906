import { IAccountInformation } from '../../../api/getAccountInformation';
import { useInterestSettingsChange } from '../../../useHooks/componentHooks/useInterestSettingsChange/useInterestSettingsChange';
import { RadioButton } from '../../RadioButton/RadioButton';
import { IInterestSettingsProps } from '../interfaces/IInterestSettingsChangeProps';
import './MobileInterestSettingsChange.scss';
import arrowUp from '../../../assets/arrowUp.svg';
import redWarning from '../../../assets/redWarning.svg';
import { IExternalAccounts } from '../../../api/User/interfaces';
import bluePlus from '../../../assets/bluePlus.svg';
import blueSettings from '../../../assets/blueSettings.svg';
import { ToastMessageDB } from '../../../shared/digitalbank/ToastMessage/ToastMessage';
import MaxAccountsModal from '../../../Pages/UserProfile/components/MaxAccountsModal/MaxAccountsModal';
import { Button, TIMER_IDS } from '../../Button/Button';

/**
 * The component renders the account interest settings page for the mobile version
 */
export const MobileInterestSettingsChange = (props: IInterestSettingsProps) => {
  const { setChangeClicked, accountToTransfer, setAccountToTransfer } = props;
  const { showExternalAccounts, setShowExternalAccounts, CDAccounts, savingsAccounts, selectedAccount,
          convertAccountToNameTypeNumber, activeSave, linkedExternalAccounts, handleOnSave, history, type, message, handleLinkExternalAccount,
          showLimitModal, setShowLimitModal} = useInterestSettingsChange(setChangeClicked, accountToTransfer, setAccountToTransfer);

  return (
    <div className="mobile-interest-settings-change">
      {showLimitModal &&
        <MaxAccountsModal setShowMaxAccountsModal={setShowLimitModal} />
      }
      <div className="encapsulation">
        <div className="title-wrap">
          <span className="title-text mobileh4">Manage Interest Payments</span>
          <span className="sub-title-text mobileRegular">
            Change where your interest payments are deposited
          </span>
        </div>
        <div className="mobile-card-wrapper">
          <div className="mobile-internal-account-section">
            {CDAccounts.length > 0 && (
              <>
                <div className="mobile-account-title boldSmall">CD account</div>
                <div className="mobile-radio-section">
                  {CDAccounts?.map((e: IAccountInformation) => {
                    return (
                      <div key={e.accountNumber} className="mobile-row-wrapper">
                        <RadioButton
                          value={convertAccountToNameTypeNumber(e)}
                          name="interest account"
                          checked={
                            e.id === accountToTransfer.id
                          }
                          onChange={() => setAccountToTransfer(JSON.parse(JSON.stringify(e)))}
                          type="Mobile"
                          origin="Interest"
                        >
                          <div className="radio-label">
                            <span>{`${e.accountTitle}`}</span>
                            <span className="mobile-radio-account x-small">
                              {e.accountType}{' '}
                              {e.accountNumber.slice(
                                e.accountNumber.length - 4,
                                e.accountNumber.length
                              )}
                            </span>
                          </div>
                        </RadioButton>
                        {e.accountNumber ===
                          selectedAccount.selectedInterestAccount
                            .accountNumber && (
                            <div className="current-selection mobile-h6">
                              Current Selection
                            </div>
                          )}
                      </div>
                    );
                  })}
                </div>
              </>
            )}
          </div>
        </div>
        <div className="mobile-card-wrapper">
          <div className="mobile-internal-account-section">
            {savingsAccounts.length > 0 && (
              <>
                <div className="mobile-account-title boldSmall">
                  Savings accounts
                </div>
                <div className="mobile-radio-section">
                  {savingsAccounts?.map((e: IAccountInformation) => {
                    return (
                      <div key={e.id}>
                        {(!e.accountClosed && !e.pendingGraceClosure) &&  
                          <div className="mobile-row-wrapper">
                            <RadioButton
                              value={convertAccountToNameTypeNumber(e)}
                              name="interest account"
                              checked={
                                e.id === accountToTransfer.id
                              }
                              onChange={() => setAccountToTransfer(JSON.parse(JSON.stringify(e)))}
                              type="Mobile"
                              origin="Interest"
                            >
                              <div className="radio-label">
                                <span>{`${e.accountTitle}`}</span>
                                <span className="mobile-radio-account x-small">
                                  {e.accountType}{' '}
                                  {e.accountNumber.slice(
                                    e.accountNumber.length - 4,
                                    e.accountNumber.length
                                  )}
                                </span>
                              </div>
                            </RadioButton>
                            {e.accountNumber ===
                              selectedAccount.selectedInterestAccount
                                .accountNumber && (
                                <div className="current-selection mobile-h6">
                                  Current Selection
                                </div>
                              )}
                          </div>
                        }
                      </div>          
                    );
                  })}
                </div>
              </>
            )}
          </div>
        </div>
        <div className="mobile-card-wrapper">
          <div className="mobile-internal-account-section">
            <div
              className={
                showExternalAccounts
                  ? 'mobile-external-account-header'
                  : 'mobile-external-account-header hidden'
              }
            >
              <span className="title-text boldSmall">External accounts</span>
              <img
                className={showExternalAccounts ? 'up-arrow' : 'down-arrow'}
                src={arrowUp}
                alt={showExternalAccounts ? 'up arrow' : 'down arrow'}
                onClick={() => setShowExternalAccounts(!showExternalAccounts)}
              />
            </div>
            {showExternalAccounts && (
              <>
                <span className="sub-title-text mobileSmall">
                  You can link up to 3 external accounts
                </span>
                <div className="mobile-radio-section">
                  {linkedExternalAccounts?.map((e: IExternalAccounts) => {
                    return (
                      <div key={e.id}>
                        <div className="mobile-row-wrapper">
                          <RadioButton
                            value={`${e.accountNickName}`}
                            name="interest account"
                            onChange={() => {
                              if (e.status === "Linked") {
                                setAccountToTransfer(JSON.parse(JSON.stringify(e)));
                              }
                            }}
                            checked={
                              e.id ===
                              accountToTransfer.id && e.status === "Linked"
                            }
                            type="Mobile"
                            disabled={e.status !== "Linked"}
                            origin="Interest"
                          >
                            <div className="radio-label">
                              <span>{`${e.accountNickName}`}</span>
                              <span className="external-text x-small">{`External ${e.lastFour}`}</span>
                              {/* <span className="mobile-radio-account small">
                              {e.accountType}{' '}
                              {e.id}
                            </span> */}
                            </div>
                          </RadioButton>
                          {e.id ===
                            selectedAccount.selectedInterestAccount
                              .id && (
                              <div className="current-selection mobile-h6">
                                Current Selection
                              </div>
                            )}
                        </div>
                        {e.status !== "Linked" && (
                          <div className="mobile-verification-wrapper">
                            <img
                              className="mobile-verification-img"
                              src={redWarning}
                              alt="warning"
                            />
                            <span className="mobile-verification-text small">
                              Verification pending
                            </span>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
                <div className="border-line" />
                <div className="mobile-external-account-links">
                  <img
                    className="blue-symbol"
                    src={bluePlus}
                    alt="add external account"
                    onClick={() => handleLinkExternalAccount()}
                  />
                  <span className="link-text small" onClick={() => handleLinkExternalAccount()}>Link external account </span>
                </div>
                <div className="border-line" />
                <div className="mobile-external-account-links" >
                  <img
                    className="blue-symbol"
                    src={blueSettings}
                    alt="manage accounts"
                    onClick={() => { history.push('/user/profile/external-accounts') }}
                  />
                  <span className="link-text small" onClick={() => { history.push('/user/profile/external-accounts') }}>Manage external accounts</span>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="mobile-button-row">
        <button
          className="cancel-button mobileBoldRegular"
          onClick={() => {setChangeClicked(false); setAccountToTransfer(selectedAccount.selectedInterestAccount)}}
        >
          Cancel
        </button>
        <Button 
          title="Save"
          isDisabled={!activeSave}
          className={activeSave ? 'Button btn-primary' : 'Button btn-primary inactive'}
          id={TIMER_IDS.INTEREST_PAYMENTS}
          onClick={handleOnSave}
        />
      </div>
      {message !== "" && <ToastMessageDB type={type} message={message} /> }
    </div>
  );
};
